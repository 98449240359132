/**
 * Functions for determining logged in state
 * Uses cookies. Running locally, this would not work. But if the apps are deployed and on the same domain, it should
 */

import Cookies from 'js-cookie';
import { UserType } from '@mvrb-frontend/shared-ui';
import { navigate } from 'gatsby';

export const getUser = () => {
  return Cookies.getJSON('ORS')?.profile || null;
};

export const isLoggedIn = () => {
  if (Cookies.getJSON('ORS') && Cookies.getJSON('ORS').profile) {
    return true;
  }

  return false;
};

export const getUserType = () => {
  if (!Cookies.getJSON('ORS')?.profile) {
    return null;
  }
  return Cookies.getJSON('ORS').profile.userType;
};

export const getUserId = () => {
  if (!Cookies.getJSON('ORS')?.profile) return null;
  return Cookies.getJSON('ORS').profile.id;
};

export const logout = async (timedOut?: boolean) => {
  Cookies.remove('ORS');
  navigate(
    `${process.env.GATSBY_ORS_BASE_URL}${
      timedOut ? `/timeout/:timedout=true` : ''
    }`,
  );
};

export const isStaffUser = () => {
  return Cookies.getJSON('ORS')
    ? Cookies.getJSON('ORS').profile?.userType === UserType.Staff
    : false;
};

export const isAdminUser=()=>{
  return Cookies.getJSON('ORS') ? Cookies.getJSON('ORS').profile?.userType === UserType.Admin : false;
}

export const isProponentUser = proponentUsers => {
  if (!isLoggedIn) {
    return false;
  }
  const userId = Cookies.getJSON('ORS')?.profile?.id;

  return proponentUsers?.some(user => user.id === userId);
};
