const orsBaseUrl = process.env.GATSBY_ORS_BASE_URL;

export const OrsRoutes = {
  OrsBase: orsBaseUrl,
  CreateReview: `${orsBaseUrl}/create-item/Review`,
  EditReview: `${orsBaseUrl}/review/:id/edit`,
  CreateIR: `${orsBaseUrl}/create-item/IR`,
  SystemManagement: `${orsBaseUrl}/system-management/users`,
  RedistributeReview: `${orsBaseUrl}/review/:reviewId/redistribute`,
  AnalyticsDashboard: `${orsBaseUrl}/analytics-dashboard`,
  editUserProfile: `${orsBaseUrl}/edit-user-profile`,
  newsLetterSignUp: `${orsBaseUrl}/newsletter-signup`
};
