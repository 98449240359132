import moment from 'moment';

export const configureMoment = () => {
  moment.updateLocale('en', {
    monthsShort: [
      'Jan',
      'Feb',
      'March',
      'April',
      'May',
      'June',
      'July',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ],
  });
};
