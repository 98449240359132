"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.proponentResponsesDueLabel = exports.reviewerCommentsDueLabel = exports.PermitMap = exports.PermitLabels = exports.ReviewParadigm = exports.InformationRequestItemLabels = exports.ReviewItemLabels = exports.ReviewType = exports.UserType = exports.NewsLetterSubmissionType = exports.UserSubmissionType = exports.SubmissionType = exports.SORT_ORDER = exports.SIZE_CONVERTER = exports.CommentOrigin = void 0;
var CommentOrigin;
(function (CommentOrigin) {
    CommentOrigin["Online"] = "Online";
    CommentOrigin["Import"] = "Import";
})(CommentOrigin = exports.CommentOrigin || (exports.CommentOrigin = {}));
exports.SIZE_CONVERTER = ['B', 'KB', 'MB', 'GB'];
exports.SORT_ORDER = ['DESC', 'ASC'];
var SubmissionType;
(function (SubmissionType) {
    SubmissionType["Add"] = "ADD";
    SubmissionType["Update"] = "UPDATE";
    SubmissionType["Delete"] = "DELETE";
    SubmissionType["UpdateDragDrop"] = "UPDATEDND";
})(SubmissionType = exports.SubmissionType || (exports.SubmissionType = {}));
var UserSubmissionType;
(function (UserSubmissionType) {
    UserSubmissionType[UserSubmissionType["UpdateOwn"] = 0] = "UpdateOwn";
    UserSubmissionType[UserSubmissionType["UpdateByStaff"] = 1] = "UpdateByStaff";
    UserSubmissionType[UserSubmissionType["AddByStaff"] = 2] = "AddByStaff";
    UserSubmissionType[UserSubmissionType["Register"] = 3] = "Register";
    UserSubmissionType[UserSubmissionType["Delete"] = 4] = "Delete";
})(UserSubmissionType = exports.UserSubmissionType || (exports.UserSubmissionType = {}));
var NewsLetterSubmissionType;
(function (NewsLetterSubmissionType) {
    NewsLetterSubmissionType[NewsLetterSubmissionType["Submit"] = 0] = "Submit";
    NewsLetterSubmissionType[NewsLetterSubmissionType["Update"] = 1] = "Update";
    NewsLetterSubmissionType[NewsLetterSubmissionType["Delete"] = 2] = "Delete";
    NewsLetterSubmissionType[NewsLetterSubmissionType["UpdatePreferences"] = 3] = "UpdatePreferences";
})(NewsLetterSubmissionType = exports.NewsLetterSubmissionType || (exports.NewsLetterSubmissionType = {}));
var UserType;
(function (UserType) {
    UserType["Staff"] = "Staff";
    UserType["User"] = "User";
    UserType["Admin"] = "Admin";
})(UserType = exports.UserType || (exports.UserType = {}));
var ReviewType;
(function (ReviewType) {
    ReviewType["Review"] = "Review";
    ReviewType["InformationRequest"] = "IR";
})(ReviewType = exports.ReviewType || (exports.ReviewType = {}));
exports.ReviewItemLabels = {
    breadcrumbs: 'Items For Review',
    boardPrefix: 'Item for Review Submitted to:',
    editItem: 'Edit Review Item',
    completeItem: 'Mark Review As Completed',
    activeItem: 'Move Review Back to Active',
    print: 'Print This Review Item',
    fileNumber: 'File Number',
    documentsPrefix: 'This Item for Review has',
    noDocuments: 'This Item for Review has no documents',
    submitCommentsOnlineTitle: 'Ready to add your voice?',
    submitCommentsOnline: 'Submit Recommendations Online',
    submitCommentsOnlineBox: 'Use our online tool to submit your written or audio comments. You can save your progress and submit when ready.',
    addCommentsOnlineText: 'Add Comments Online',
    uploadCommentsButton: 'Upload Comment Excel File',
    noAttachments: 'This Item for Review has no attachments',
    noRelatedItems: 'This Item for Review has no related reviews',
    attachmentsListPrefix: 'The public review contains ',
    infoBox1: 'This public review is currently accepting comments.',
    infoBox2: 'The comment period will close in',
    submitText: 'Submit',
    comment: 'Comment',
    recommendation: 'Recommendation',
    addCommentPlaceholder: 'Add your comment',
    addRecommendationPlaceholder: 'Add your recommendation',
    addRecommendationOnBehalf: 'Add recommendations on behalf of',
    submitResponsesOnline: 'Submit your Responses online',
    submitResponsesOnlineBox: 'Use our online tool to submit your responses.',
    addAnotherRow: 'Add Another Comment Row',
    addAudioRow: 'Add Audio Comment',
    itemNoComments: 'This Item for Review has no comments',
};
exports.InformationRequestItemLabels = {
    breadcrumbs: 'Information Request',
    boardPrefix: 'Information Request Phase for ',
    editItem: 'Edit Information Request',
    completeItem: 'Mark Information Request As Completed',
    activeItem: 'Move Information Request Back to Active',
    print: 'Print Information Request',
    fileNumber: 'File/EA Number',
    documentsPrefix: 'This Information Request has',
    noDocuments: 'This Information Request has no documents',
    submitCommentsOnlineTitle: 'Ready to add your voice?',
    submitCommentsOnline: 'Submit Information Request Online',
    submitCommentsOnlineBox: 'Use our online tool to submit your information requests.',
    addCommentsOnlineText: 'Add Request Online',
    uploadCommentsButton: 'Upload Completed Excel File',
    noAttachments: 'This Information Request has no attachments',
    noRelatedItems: 'This Information Request has no related reviews',
    attachmentsListPrefix: 'This Information Request contains ',
    infoBox1: 'This public review is currently in an information request phase.',
    infoBox2: 'The Information Request period will close in',
    submitText: 'Submit',
    comment: 'Preamble',
    recommendation: 'Request',
    addCommentPlaceholder: 'Add your preamble',
    addRecommendationPlaceholder: 'Add your request',
    addRecommendationOnBehalf: 'Add requests on behalf of',
    submitResponsesOnline: 'Submit your Responses online',
    submitResponsesOnlineBox: 'Use our online tool to submit your responses.',
    addAnotherRow: 'Add Another Row',
    addAudioRow: 'Add Audio Request',
    itemNoComments: 'This Information Request has no comments',
};
exports.ReviewParadigm = [
    { value: 'New', label: 'New Application' },
    { value: 'Renewal', label: 'Renewal Application' },
    { value: 'Amendment', label: 'Amendment' },
    { value: 'Extension', label: 'Extension' },
    { value: 'Assignment', label: 'Assignment' },
    { value: 'Plan/Program', label: 'Plan/Report' },
    { value: 'Preliminary Screening', label: 'Preliminary Screening' },
    { value: 'Security Adjustment', label: 'Security Adjustment' },
    { value: 'SNP Change', label: 'SNP Change' },
    { value: 'Policy/Guideline', label: 'Policy/Guideline' },
    { value: 'WMMP/WEMP', label: 'WMMP/WEMP' },
];
exports.PermitLabels = [
    { value: 'Permit', label: 'Permit - Type A' },
    { value: 'Perm B', label: 'Permit - Type B' },
    { value: 'Licence', label: 'Licence - Type A' },
    { value: 'Lic B', label: 'Licence - Type B' },
];
exports.PermitMap = {
    Permit: 'Permit - Type A',
    PermB: 'Permit - Type B',
    Licence: 'Licence - Type A',
    LicB: 'Licence - Type B',
    PublicHearingProceedings: 'Public Hearing Proceedings',
    EnvironmentalAssessment: 'Environmental Assessment',
    EnvironmentalImpactReview: 'Environmental Impact Review',
    Other: 'Policy/Guideline',
    'WMMP/WEMP': 'WMMP/WEMP',
};
exports.reviewerCommentsDueLabel = (type) => type === ReviewType.InformationRequest ? 'Deadline for Parties to Submit IRs' : 'Review Comments Due';
exports.proponentResponsesDueLabel = (type) => type === ReviewType.InformationRequest ? 'Deadline for Parties to Reply to IRs' : 'Proponent Responses Due';
