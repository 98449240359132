import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';
import { NavBar, Wrapper } from '@mvrb-frontend/shared-ui';
import { navigate } from 'gatsby';
import { isLoggedIn, logout, isStaffUser, isAdminUser } from '../utils/auth';
import { OrsRoutes } from '../constants/routes';
import { configureMoment } from '../utils/moment-config';

configureMoment();

const Page = (props: any) => {
  const { children, isFetching, container } = props;
  const content = isFetching ? <LinearProgress /> : children;
  const isStaff = isStaffUser();
  const isAdmin = isAdminUser();
  const isLogged = isLoggedIn();

  function getInitials() {
    if (!isLogged) {
      return '';
    }
    const profile = Cookies.getJSON('ORS').profile;
    return `
      ${profile.firstName?.charAt(0).toUpperCase()}
      ${profile.lastName?.charAt(0).toUpperCase()}`;
  }

  const isAdminOrStaff=()=>{
    if(isAdmin||isStaff){
      return true;
    }
    else{
      return false;
    }
  }

  const navBar: React.ReactElement = (
    <NavBar
      authActionSubmit={() => {
        if (isLogged) {
          logout();
        } else {
          navigate(OrsRoutes.OrsBase);
        }
      }}
      links={[
        {
          title: 'Home',
          value: '/',
          onClick: () => navigate('/'),
        },
        {
          title: 'Reviews',
          value: '/reviews',
          onClick: () => window.open('/reviews', '_self'),
        },
        {
          title: 'Analytics Dashboard',
          value: OrsRoutes.AnalyticsDashboard,
        },
        {
          title: 'User Manual',
          value: process.env.GATSBY_USER_MANUAL_PDF_URL,
          onClick: () =>
            window.open(process.env.GATSBY_USER_MANUAL_PDF_URL, '_blank'),
        },
        {
          title: 'System Management',
          value: OrsRoutes.SystemManagement,
          visible: isAdminOrStaff(),
        },
      ]}
      loggedIn={isLogged}
      userInitials={getInitials()}
      onUserInitialClick={() =>
        navigate(`${process.env.GATSBY_ORS_BASE_URL}/edit-user-profile`)
      }
      onNewsLetterClick={()=>{
        if(isLogged){
          navigate(`${process.env.GATSBY_ORS_BASE_URL}/edit-user-profile`)
        }
        else{
          navigate(`${process.env.GATSBY_ORS_BASE_URL}/newsletter-signup`)
        }
      }}
      newsLetterOrigin={`website`}
    />
  );

  return (
    <Wrapper isFetching={isFetching} navBar={navBar} container={container}>
      {content}
    </Wrapper>
  );
};

Page.propTypes = {
  children: PropTypes.any.isRequired,
  isFetching: PropTypes.bool.isRequired,
  container: PropTypes.bool,
};

export default Page;
